<template>
    <marketing-layout>
        <marketing-hero>
            <h1 class="fw-bold heading-3 text-responsive mb-3">
                {{ $t('pages.marketing.licenses.title') }}
            </h1>
        </marketing-hero>
        <section-block class="pt-0">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <table class="table fixed">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        State
                                    </th>
                                    <th scope="col">
                                        Name
                                    </th>
                                    <th scope="col">
                                        #
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(license, index) in licenses"
                                    :key="index"
                                >
                                    <td v-html="license.state" />
                                    <td v-html="license.name" />
                                    <td v-html="license.number" />
                                </tr>
                            </tbody>
                        </table>
                        <p class="small text-muted">
                            For licensing information, go to
                            <a href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2042345">www.nmlsconsumeraccess.org</a>.
                        </p>
                        <p class="small text-muted">
                            Aven cards are arranged by Aven Financial, Inc. NMLS #2042345. Contact Support at 1 (888) 966-4655.
                        </p>
                        <p class="small text-muted">
                            CA Only. Aven Financial, Inc. is a realestate broker licensed by the California Department of Real Estate. License #02128983. Responsible broker Anthony Smernes, Jr.,
                            license #00477863.
                        </p>
                        <p class="small text-muted">
                            Note for customers in AK, AL, IA, KS, LA, ND, NE, NH, OK, OR, SD, WY: We currently only offer lines up to $100,000. Check back soon for higher line sizes.
                        </p>
                    </div>
                </div>
            </div>
        </section-block>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import MarketingHero from '@/components/MarketingHero'
    import SectionBlock from '@/components/SectionBlock'
    import { licenses } from '@/utils/stateLicenses'

    export default {
        name: 'Licenses',
        metaInfo: {
            title: 'Licenses',
        },
        data: function () {
            return {
                licenses: licenses,
            }
        },
        mounted: function () {
            this.$logEvent('view_licenses')
        },
        components: {
            'marketing-layout': Marketing,
            MarketingHero,
            SectionBlock,
        },
    }
</script>
