<template>
    <marketing-layout>
        <marketing-hero>
            <h1 class="fw-bold heading-3 text-responsive mb-3">
                {{ $t('pages.marketing.mobileApps.title') }}
            </h1>
            <h2 class="fw-light heading-5 text-responsive mb-0">
                {{ $t('pages.marketing.mobileApps.subTitle') }}
            </h2>
        </marketing-hero>
        <section-block class="pt-0">
            <div class="container">
                <div class="col-12 mt-3 mt-md-5">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <list-button
                                :title="$t('pages.marketing.mobileApps.iphone')"
                                image-src="global/logo-apple.svg"
                                href="https://apps.apple.com/us/app/aven-card/id1541705487?itsct=apps_box&itscg=30200"
                            />
                        </div>
                        <div class="col-12 col-lg-6">
                            <list-button
                                :title="$t('pages.marketing.mobileApps.android')"
                                image-src="global/logo-play-store.svg"
                                href="https://play.google.com/store/apps/details?id=com.aven.app&hl=en_US"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section-block>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import MarketingHero from '@/components/MarketingHero'
    import ListButton from '@/components/ListButton'
    import SectionBlock from '@/components/SectionBlock'

    export default {
        name: 'MobileApps',
        metaInfo: {
            title: 'Get The Aven App',
            meta: [{ name: 'description', vmid: 'description', content: 'Download the Aven Card app to easily view and manage your card. Available for iPhone and Android.' }],
        },
        mounted: function () {
            this.$logEvent('view_mobile_apps')
        },
        components: {
            'marketing-layout': Marketing,
            MarketingHero,
            ListButton,
            SectionBlock,
        },
    }
</script>

<style scoped>
    .underline {
        border-bottom: 2px solid black;
        width: 28px;
        margin: 0 auto;
    }
</style>
