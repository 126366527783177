// the order of the elements how they are displayed
export const licenses = [
    {
        state: 'Alaska',
        name: 'Mortgage Broker/Lender License',
        number: 'AK2042345 <br/> AK2042345-1',
    },
    {
        state: 'Alabama',
        name: 'Mortgage Broker License',
        number: '23100',
    },
    {
        state: 'Arizona',
        name: 'Mortgage Banker License',
        number: '1031386',
    },
    {
        state: 'California',
        name: 'Real Estate Corporation License',
        number: '2128983',
    },
    {
        state: 'Colorado',
        name: 'Mortgage Company Registration',
        number: '2042345',
    },
    {
        state: 'Florida',
        name: 'Mortgage Lender Servicer License',
        number: 'MLD2173',
    },
    {
        state: 'Georgia',
        name: 'Mortgage Lender License / Registration',
        number: '2042345',
    },
    {
        state: 'Illinois',
        name: 'Residential Mortgage License',
        number: 'MB.6761669',
    },
    {
        state: 'Idaho',
        name: 'Mortgage Broker/Lender License',
        number: 'MBL-2082042345',
    },
    {
        state: 'Iowa',
        name: 'Mortgage Banker License',
        number: '2022-0021',
    },
    {
        state: 'Kansas',
        name: 'Mortgage Company License',
        number: 'MC.0025820',
    },
    {
        state: 'Kentucky',
        name: 'Mortgage Company License',
        number: 'MC778783',
    },
    {
        state: 'Louisiana',
        name: 'Residential Mortgage Lending License',
        number: '2042345',
    },
    {
        state: 'Maine',
        name: 'Supervised Lender License <br/> UCCC Notification',
        number: '2042345',
    },
    {
        state: 'Maryland',
        name: 'Mortgage Lender License',
        number: '2042345',
    },
    {
        state: 'Michigan',
        name: '1st Mortgage Broker/Lender/Servicer License <br/> 2nd Mortgage Broker/Lender/Servicer Registration',
        number: 'FL0024301 <br/> SR0024302',
    },
    {
        state: 'Montana',
        name: 'Montana Mortgage Broker License',
        number: '2042345',
    },
    {
        state: 'Nebraska',
        name: 'Mortgage Banker License',
        number: '2042345',
    },
    {
        state: 'North Dakota',
        name: 'Money Broker License',
        number: 'MB104010',
    },
    {
        state: 'New Hampshire',
        name: 'Mortgage Banker License',
        number: '24743-MB',
    },
    {
        state: 'Ohio',
        name: 'Residential Mortgage Lending Act Certificate of Registration',
        number: 'RM.804904.000',
    },
    {
        state: 'Oklahoma',
        name: 'Mortgage Lending License',
        number: 'ML014577',
    },
    {
        state: 'Oregon',
        name: 'Mortgage Lender License <br/> Mortgage Servicer License',
        number: '2042345',
    },
    {
        state: 'South Dakota',
        name: 'Mortgage Lender License',
        number: '2042345.ML',
    },
    {
        state: 'Tennessee',
        name: 'Tennessee Mortgage License',
        number: '2042345',
    },
    {
        state: 'Texas',
        name: 'Mortgage Company License <br/> Residential Mortgage Loan Servicer Registration <br/> Regulated Loan Act License',
        number: '2042345',
    },
    {
        state: 'Washington DC',
        name: 'Mortgage Dual Authority License',
        number: 'MLB2042345',
    },
    {
        state: 'Wyoming',
        name: 'Mortgage Broker License',
        number: '4472',
    },
]
